<template>
  <div>
    <b-modal
      :id="id"
      size="xl"
      :title="title"
      hide-footer
    >
      <Table :purpose="purpose" @chooseData="chooseData" :filterProps="filterProps"/>
    </b-modal>
  </div>
</template>

<script>
import Table from "@/view/components/users/Table2.vue";

export default {
  props: {
    filterProps: Object,
    id: String,
    purpose: String,
    title: {
      type: String,
      default: "Daftar Pengguna"
    }
  },

  components: {
    Table,
  },

  methods: {
    chooseData(value) {
      this.$emit("chooseData", value);
    },
  },
};
</script>

<style>
</style>
