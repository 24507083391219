<template>
  <div>
    <!-- Filter -->
    <div class="row justify-content-end mt-n3">
      <div class="col-md-3" v-if="filterProps.role_id == ''">
        <treeselect
          v-model="filter.role_id"
          :multiple="false"
          placeholder="Saring Peran"
          :options="roles"
          @input="filterByRole"
        />
      </div>
      <div class="col-md-4">
        <b-input-group>
          <template #append>
            <b-button
              variant="success"
              squared
              size="sm"
              @click="search"
              v-b-tooltip.hover
              title="Cari"
            >
              <i class="fas fa-search px-0"></i>
            </b-button>
            <b-button
              variant="danger"
              squared
              size="sm"
              @click="reset"
              v-b-tooltip.hover
              title="Reset"
            >
              <i class="fas fa-recycle px-0"></i>
            </b-button>
          </template>
          <b-form-input
            type="text"
            v-model="filter.name"
            placeholder="Cari Nama/NIP/NIM"
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <!-- table -->
    <b-table
      striped
      hover
      :items="items"
      :fields="fields"
      responsive
      class="mt-3"
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{
            width: field.key === 'action' ? '15%' : '',
          }"
        />
      </template>

      <template #cell(photo)="data">
        <div
          class="
            d-flex
            justify-content-start
            align-items-center
            image-container
          "
        >
          <div class="image-input-wrapper" style="position: relative">
            <img class="image" :src="data.item.photo" />
          </div>
        </div>
      </template>

      <template #cell(action)="data">
        <template v-if="purpose == 'modal-user2'">
          <b-button
            size="sm"
            class=""
            variant="primary"
            @click="
              chooseData({
                id: data.item.id,
                name: data.item.name,
                role: data.item.role_id,
              })
            "
          >
            Pilih
          </b-button>
        </template>
        <template v-if="purpose == 'modal-laboran'">
          <b-button
            size="sm"
            class=""
            variant="primary"
            @click="
              chooseData({
                id: data.item.id,
                name: data.item.name,
                role: data.item.role_id,
              })
            "
          >
            Pilih
          </b-button>
        </template>
        <template v-if="purpose == 'modal-teacher'">
          <b-button
            size="sm"
            class=""
            variant="primary"
            @click="
              chooseData({
                id: data.item.id,
                name: data.item.name,
                role: data.item.role_id,
              })
            "
          >
            Pilih
          </b-button>
        </template>
      </template>
    </b-table>
    <b-pagination
      v-if="items.length != 0"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import Table from "@/view/components/users/Table.vue";
import ApiService from "@/core/services/api.service.js";

export default {
  props: {
    purpose: String,
    filterProps: Object
  },
  components: {
    Table,
  },
  data() {
    return {
      // filter
      filter: {
        name: "",
        role_id: this.filterProps.role_id,
        major_id: "",
        school_year_id: "",
        class_group_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo",
          label: "Foto",
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "registration_number",
          label: "NIP/NIM",
          sortable: true,
        },
        {
          key: "role_name",
          label: "Peran",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      roles: [],
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&role_id=${this.filter.role_id}&major_id=${this.filter.major_id}&school_year_id=${this.filter.school_year_id}&class_group_id=${this.filter.class_group_id}`;
      let response = await module.paginate(
        "api/users",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    async getRoleOption() {
      let response = await module.setTreeSelect("api/roles");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.roles = response.data;
        this.roles.unshift({
          label: "Saring Peran",
          id: "",
          isDisabled: true,
        });
      }
    },

    filterByName() {
      this.pagination();
    },

    chooseData(value) {
      this.$emit("chooseData", value);
    },

    filterByRole(evt) {
      if (typeof evt == "undefined") {
        this.filter.role_id = "";
      }
      this.pagination();
    },

    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      if(this.filterProps.role_id == ''){
        this.filter.role_id = "";
      }
      this.pagination();
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.getRoleOption();
    this.checkAccess();
    this.pagination();
  },
};
</script>
