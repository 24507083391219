var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row justify-content-end mt-n3"},[(_vm.filterProps.role_id == '')?_c('div',{staticClass:"col-md-3"},[_c('treeselect',{attrs:{"multiple":false,"placeholder":"Saring Peran","options":_vm.roles},on:{"input":_vm.filterByRole},model:{value:(_vm.filter.role_id),callback:function ($$v) {_vm.$set(_vm.filter, "role_id", $$v)},expression:"filter.role_id"}})],1):_vm._e(),_c('div',{staticClass:"col-md-4"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"success","squared":"","size":"sm","title":"Cari"},on:{"click":_vm.search}},[_c('i',{staticClass:"fas fa-search px-0"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"variant":"danger","squared":"","size":"sm","title":"Reset"},on:{"click":_vm.reset}},[_c('i',{staticClass:"fas fa-recycle px-0"})])]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama/NIP/NIM"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}})],1)],1)]),_c('b-table',{staticClass:"mt-3",attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
            width: field.key === 'action' ? '15%' : '',
          })})})}},{key:"cell(photo)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-start align-items-center image-container"},[_c('div',{staticClass:"image-input-wrapper",staticStyle:{"position":"relative"}},[_c('img',{staticClass:"image",attrs:{"src":data.item.photo}})])])]}},{key:"cell(action)",fn:function(data){return [(_vm.purpose == 'modal-user2')?[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.chooseData({
                id: data.item.id,
                name: data.item.name,
                role: data.item.role_id,
              })}}},[_vm._v(" Pilih ")])]:_vm._e(),(_vm.purpose == 'modal-laboran')?[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.chooseData({
                id: data.item.id,
                name: data.item.name,
                role: data.item.role_id,
              })}}},[_vm._v(" Pilih ")])]:_vm._e(),(_vm.purpose == 'modal-teacher')?[_c('b-button',{attrs:{"size":"sm","variant":"primary"},on:{"click":function($event){return _vm.chooseData({
                id: data.item.id,
                name: data.item.name,
                role: data.item.role_id,
              })}}},[_vm._v(" Pilih ")])]:_vm._e()]}}])}),(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.pageOnClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }